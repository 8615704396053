var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copy-stake-gamification-center-sign-up-reward-table"},[_c('div',{staticClass:"copy-stake-gamification-center-sign-up-reward-table__filter copy-stake-gamification-center-sign-up-reward-table-filter"},[_c('v-select',{staticClass:"copy-stake-gamification-center-sign-up-reward-table-filter__search-by",attrs:{"items":_vm.searchByOptions,"menu-props":{ offsetY: true },"item-value":"value","outlined":"","dense":"","hide-details":""},model:{value:(_vm.tmpFilterOptions.searchBy),callback:function ($$v) {_vm.$set(_vm.tmpFilterOptions, "searchBy", $$v)},expression:"tmpFilterOptions.searchBy"}}),_c('div',{staticClass:"copy-stake-gamification-center-sign-up-reward-table-filter__search"},[_c('v-text-field',{staticClass:"copy-stake-gamification-center-sign-up-reward-table-filter__search-input",attrs:{"placeholder":_vm.searchPlaceholder,"outlined":"","hide-details":"","dense":"","clearable":""},on:{"click:clear":_vm.handleClickClearSearchInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleKeydownEnterSearchInput.apply(null, arguments)}},model:{value:(_vm.tmpFilterOptions.search),callback:function ($$v) {_vm.$set(_vm.tmpFilterOptions, "search", $$v)},expression:"tmpFilterOptions.search"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleClickSearchButton}},[_vm._v("Search")])],1)],1),_c('v-data-table-with-pagination',{staticClass:"copy-stake-gamification-center-sign-up-reward-table__table",attrs:{"loading":_vm.isSignUpRewardStreamerLoading,"headers":_vm.headers,"options":_vm.paginationOptions,"items":_vm.signUpRewardStreamers,"server-items-length":_vm.totalSignUpRewardStreamers,"mobile-breakpoint":1024,"disable-sort":""},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.operatorUserId",fn:function(ref){
var item = ref.item;
return [_c('CopyAddress',{attrs:{"address":item.operatorUserId,"start":16,"end":16,"fallback":'-'}})]}},{key:"item.walletAddress",fn:function(ref){
var item = ref.item;
return [_c('CopyAddress',{attrs:{"address":item.walletAddress,"start":16,"end":16,"fallback":'-'}})]}},{key:"item.nickname",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.nickname ? _vm.truncateString(item.nickname, 20) : '-'))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"prev",undefined,$event.key,undefined)){ return null; }$event.stopPropagation();return _vm.handleClickRemoveButton(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}])}),_c('ConfirmationDialog',{attrs:{"processing":_vm.dialogs.loading,"data":_vm.dialogs.data,"closable":""},on:{"close":function () { return _vm.closeDialog('confirm'); }},model:{value:(_vm.dialogs.confirm),callback:function ($$v) {_vm.$set(_vm.dialogs, "confirm", $$v)},expression:"dialogs.confirm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }