






import { textOverflowMiddle } from '@/helpers/formatString';

import CopyBtn from '@/components/core/CopyBtn.vue';

export default {
  components: {
    CopyBtn
  },
  name: 'CopyAddress',
  props: {
    address: {
      type: String,
      default: ''
    },
    start: {
      type: Number,
      default: 10
    },
    end: {
      type: Number,
      default: 0
    },
    fallback: {
      type: String,
      default: '-'
    }
  },
  methods: {
    prepareHash(val: string): string {
      if (!val?.length) return this.fallback;

      return textOverflowMiddle(val, this.start, this.end);
    }
  }
};
