import { http } from '@/api/Connect';
import { BASE_GAME_CENTER_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosError, AxiosResponse } from 'axios';

import {
  TSignUpReward,
  TSignUpRewardStreamer,
  TSignUpRewardStreamersListParams,
  TSignUpRewardStreamersListResponse
} from '@/api/schema';

const API = {
  GET(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/sign-up-rewards`;
  },
  ENABLE_FOR_ALL(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/sign-up-rewards?allStreamersActive=true`;
  },
  DISABLE_FOR_ALL(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/sign-up-rewards?allStreamersActive=false`;
  },
  GET_STREAMERS(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/sign-up-rewards/streamers`;
  },
  ADD_STREAMERS(): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/sign-up-rewards/streamers`;
  },
  DELETE_STREAMER(id: string): string {
    return `${BASE_GAME_CENTER_OPERATOR()}/sign-up-rewards/streamers/${id}`;
  }
};

export async function getSignUpReward(): Promise<TSignUpReward> {
  return http
    .get(API.GET())
    .then(({ data }: AxiosResponse<TSignUpReward>) => data);
}

export async function enableSignUpRewardForAll(): Promise<void> {
  await http.put(API.ENABLE_FOR_ALL());
}

export async function disableSignUpRewardForAll(): Promise<void> {
  await http.put(API.DISABLE_FOR_ALL());
}

export async function getSignUpRewardStreamers(
  params: TSignUpRewardStreamersListParams
): Promise<TSignUpRewardStreamersListResponse> {
  try {
    const { data } = await http.get<TSignUpRewardStreamersListResponse>(
      API.GET_STREAMERS(),
      {
        params,
        cacheTime: 0
      }
    );

    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      return {
        content: [],
        empty: true,
        numberOfElements: 0,
        totalElements: 0,
        number: 0,
        size: 0
      };
    }

    throw error;
  }
}

export async function addSignUpRewardStreamers(data: {
  streamers: TSignUpRewardStreamer[];
}): Promise<void> {
  await http.put(API.ADD_STREAMERS(), data);
}

export async function deleteSignUpRewardStreamer(id: string): Promise<void> {
  await http.delete(API.DELETE_STREAMER(id));
}
