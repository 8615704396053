









import { copyToClipBoard } from '@/helpers/copyToClipBoard';
export default {
  name: 'CopyBtn',
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    copyToClipBoard
  }
};
