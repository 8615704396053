

























































import { errorToastMessage } from '@/helpers/errorToastMessage';
import { DataTableHeader } from 'vuetify';
import { truncateString } from '@/helpers/formatString';
import {
  ESignUpRewardStreamerSearchBy,
  TSignUpRewardStreamer,
  TSignUpRewardStreamersListParams
} from '@/api/schema';
import {
  getSignUpRewardStreamers,
  deleteSignUpRewardStreamer
} from '@/api/CopyStake/GameCenterSignUpReward';
const ConfirmationDialog = (): Promise<any> =>
  import('@/components/ConfirmationDialog.vue');
const CopyAddress = (): Promise<any> =>
  import('@/components/CopyAddress.vue');

type ISelectItem = {
  text: string;
  value: ESignUpRewardStreamerSearchBy;
};

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  itemsPerPage: 10
} as const;

const DEFAULT_FILTER_OPTIONS = {
  searchBy: ESignUpRewardStreamerSearchBy.OPERATOR_USER_ID,
  search: ''
} as const;

export default {
  name: 'CopyStakeGamificationCenterSignUpRewardTable',

  components: {
    CopyAddress,
    ConfirmationDialog
  },

  props: {
    needToRefetch: {
      type: Boolean,
      default: false
    }
  },

  data(): Record<string, unknown> {
    return {
      isSignUpRewardStreamerLoading: false,
      signUpRewardStreamers: [],
      totalSignUpRewardStreamers: null,
      paginationOptions: { ...DEFAULT_PAGINATION_OPTIONS },
      tmpFilterOptions: { ...DEFAULT_FILTER_OPTIONS },
      filterOptions: { ...DEFAULT_FILTER_OPTIONS },
      dialogs: {
        confirm: false,
        loading: false,
        data: undefined
      }
    };
  },

  computed: {
    searchByOptions(): ISelectItem[] {
      return [
        {
          text: 'Operator User ID',
          value: ESignUpRewardStreamerSearchBy.OPERATOR_USER_ID
        },
        {
          text: 'Wallet Address',
          value: ESignUpRewardStreamerSearchBy.WALLET_ADDRESS
        },
        {
          text: 'Nickname',
          value: ESignUpRewardStreamerSearchBy.STREAMER_NICKNAME
        }
      ];
    },
    currentSearchBy(): ISelectItem | null {
      return (
        this.searchByOptions.find(
          (option: ISelectItem) =>
            option.value === this.tmpFilterOptions.searchBy
        ) ?? null
      );
    },
    searchPlaceholder(): string {
      if (!this.currentSearchBy) {
        return '';
      }

      return `Search by ${this.currentSearchBy.text}`;
    },
    headers(): DataTableHeader[] {
      return [
        { text: 'Operator User ID', value: 'operatorUserId' },
        { text: 'Wallet Address', value: 'walletAddress' },
        { text: 'Nickname', value: 'nickname' },
        { text: 'Action', value: 'actions' }
      ];
    },
    requestParams(): TSignUpRewardStreamersListParams {
      return {
        ...(this.filterOptions.search
          ? { [this.filterOptions.searchBy]: this.filterOptions.search }
          : {}),
        size: this.paginationOptions.itemsPerPage,
        page: this.paginationOptions.page - 1
      };
    }
  },

  watch: {
    needToRefetch: {
      async handler(): Promise<void> {
        if (this.needToRefetch) {
          await this.fetchSignUpRewardStreamers();
        }
      }
    },
    requestParams: {
      async handler(): Promise<void> {
        await this.fetchSignUpRewardStreamers();
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    truncateString,
    closeDialog(type: string, resetData: boolean = true): void {
      this.dialogs[type] = false;

      if (resetData) {
        this.$nextTick(() => {
          this.dialogs.data = {};
        });
      }
    },
    async fetchSignUpRewardStreamers(): Promise<void> {
      if (this.isSignUpRewardStreamerLoading) {
        return;
      }

      try {
        this.isSignUpRewardStreamerLoading = true;

        const {
          content: signUpRewardStreamers,
          totalElements: totalSignUpRewardStreamers
        } = await getSignUpRewardStreamers(this.requestParams);

        this.signUpRewardStreamers = signUpRewardStreamers;
        this.totalSignUpRewardStreamers = totalSignUpRewardStreamers;

        this.$emit('update:needToRefetch', false);
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isSignUpRewardStreamerLoading = false;
      }
    },
    async deleteSignUpRewardStreamer(
      signUpRewardStreamer: TSignUpRewardStreamer
    ): Promise<void> {
      try {
        this.dialogs.loading = true;

        await deleteSignUpRewardStreamer(signUpRewardStreamer.id);
        this.closeDialog('confirm');
        this.$toast.success(
          `Streamer "${
            signUpRewardStreamer.nickname ||
            signUpRewardStreamer.operatorUserId
          }" was successfully deleted`
        );
        await this.fetchSignUpRewardStreamers();
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.dialogs.loading = false;
      }
    },
    updateRequestOptions(newOptions: Record<string, unknown>): void {
      this.filterOptions.searchBy =
        newOptions.searchBy ?? DEFAULT_FILTER_OPTIONS.searchBy;
      this.filterOptions.search =
        newOptions.search ?? DEFAULT_FILTER_OPTIONS.search;

      this.paginationOptions.page = DEFAULT_PAGINATION_OPTIONS.page;
      this.paginationOptions.itemsPerPage =
        DEFAULT_PAGINATION_OPTIONS.itemsPerPage;
    },

    handleClickClearSearchInput(): void {
      this.updateRequestOptions({
        ...this.tmpFilterOptions,
        search: ''
      });
    },
    handleKeydownEnterSearchInput(): void {
      this.updateRequestOptions(this.tmpFilterOptions);
    },
    handleClickSearchButton(): void {
      this.updateRequestOptions(this.tmpFilterOptions);
    },
    handleClickRemoveButton(streamer: TSignUpRewardStreamer): void {
      this.dialogs.confirm = true;
      this.dialogs.data = {
        disclaimer: `
          <p>
            Are you sure you want to delete streamer <strong>${
              streamer.nickname || streamer.operatorUserId
            }</strong> from the Sign-Up Reward feature?
          </p>
        `,
        icon: 'mdi-alert-octagon',
        header: 'Delete Streamer',
        okText: 'Delete',
        handler: () => this.deleteSignUpRewardStreamer(streamer)
      };
    }
  }
};
