





















































import { TSignUpRewardStreamer, TStreamer } from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import CopyStakeSelectStreamersModal from '@/views/CopyStake/CopyStakeStreamer/CopyStakeSelectStreamersModal.vue';
import CopyStakeGamificationCenterSignUpRewardTable from '@/views/CopyStake/CopyStakeGamificationCenter/SignUpReward/CopyStakeGamificationCenterSignUpRewardTable.vue';
import {
  addSignUpRewardStreamers,
  disableSignUpRewardForAll,
  enableSignUpRewardForAll,
  getSignUpReward
} from '@/api/CopyStake/GameCenterSignUpReward';
import { mapActions, mapGetters } from 'vuex';
import { ECopyStakeFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';
import { updateStateFeatureFlag } from '@/api/CopyStake/FeatureFlags';

export default {
  name: 'CopyStakeGamificationCenterSignUpRewardTab',

  components: {
    CopyStakeGamificationCenterSignUpRewardTable,
    CopyStakeSelectStreamersModal
  },

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data(): Record<string, unknown> {
    return {
      needToRefetchTableData: false,
      isAddStreamersModalVisible: false,

      isSignUpRewardFeatureFlagEnabledProcessing: false,

      isSignUpRewardProcessing: false,
      isSignUpRewardActiveForAllStreamers: false,

      isAddSignUpRewardStreamersProcessing: false
    };
  },

  computed: {
    ...mapGetters('FeatureFlags', [
      'isEnabledFeatureFlag',
      'isFeaturesLoading',
      'featureFlagIdByName'
    ]),

    signUpRewardFeatureFlagId(): number | null {
      return this.featureFlagIdByName(
        ECopyStakeFeatureFlag.GAMIFICATION_CENTER_SIGN_UP_REWARD
      );
    },
    isSignUpRewardFeatureFlagEnabled(): boolean {
      return this.isEnabledFeatureFlag(
        ECopyStakeFeatureFlag.GAMIFICATION_CENTER_SIGN_UP_REWARD
      );
    }
  },

  watch: {
    active: {
      async handler(active: boolean): Promise<void> {
        if (!active) {
          return;
        }

        await this.fetchSignUpReward();
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('FeatureFlags', ['getOperatorCopyStakeFeatureFlags']),

    openAddStreamersModal(): void {
      this.isAddStreamersModalVisible = true;
    },
    closeAddStreamersModal(): void {
      this.isAddStreamersModalVisible = false;
    },
    async fetchSignUpReward(): Promise<void> {
      if (this.isSignUpRewardProcessing) {
        return;
      }

      try {
        this.isSignUpRewardProcessing = true;

        const { allStreamersActive } = await getSignUpReward();
        this.isSignUpRewardActiveForAllStreamers = allStreamersActive;
        this.isSignUpRewardProcessing = false;
      } catch (error) {
        errorToastMessage(error);
      }
    },
    async handleClickIsSignUpRewardFeatureFlagEnabledSwitch(): Promise<void> {
      if (
        this.isSignUpRewardFeatureFlagEnabledProcessing ||
        !this.signUpRewardFeatureFlagId
      ) {
        return;
      }

      try {
        this.isSignUpRewardFeatureFlagEnabledProcessing = true;

        await updateStateFeatureFlag(
          this.signUpRewardFeatureFlagId,
          !this.isSignUpRewardFeatureFlagEnabled
        );
        await this.getOperatorCopyStakeFeatureFlags();
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isSignUpRewardFeatureFlagEnabledProcessing = false;
      }
    },
    async handleClickIsSignUpRewardEnabledForAllStreamersSwitch(): Promise<void> {
      if (this.isSignUpRewardProcessing) {
        return;
      }

      try {
        this.isSignUpRewardProcessing = true;

        if (this.isSignUpRewardActiveForAllStreamers) {
          await disableSignUpRewardForAll();
          this.isSignUpRewardActiveForAllStreamers = false;
        } else {
          await enableSignUpRewardForAll();
          this.isSignUpRewardActiveForAllStreamers = true;
        }
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isSignUpRewardProcessing = false;
      }
    },
    handleClickAddStreamersButton(): void {
      this.openAddStreamersModal();
    },
    async handleSelectCopyStakeSelectStreamersModal(
      streamers: TStreamer[]
    ): Promise<void> {
      if (this.isAddSignUpRewardStreamersProcessing) {
        return;
      }

      try {
        this.isAddSignUpRewardStreamersProcessing = true;

        const preparedStreamers: TSignUpRewardStreamer[] = streamers.map(
          (streamer) => ({
            id: streamer.id,
            nickname: streamer.nickname,
            walletAddress: streamer.playerWalletHash,
            operatorUserId: streamer.operatorUserId
          })
        );
        await addSignUpRewardStreamers({ streamers: preparedStreamers });

        this.isAddStreamersModalVisible = false;
        this.needToRefetchTableData = true;
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isAddSignUpRewardStreamersProcessing = false;
      }
    }
  }
};
