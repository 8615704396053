import { http } from '@/api/Connect';
import { BASE_WEB_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import {
  TStreamersListParams,
  IStreamerListResponse,
  TStreamer,
  IStreamersListSearchParams
} from '@/api/schema';

const API_STREAMER = {
  LIST(): string {
    return `${BASE_WEB_OPERATOR()}/streamer`; //GET
  },
  LIST_SEARCH(): string {
    return `${BASE_WEB_OPERATOR()}/streamer/search`; //GET
  },
  CREATE(): string {
    return `${BASE_WEB_OPERATOR()}/streamer`; //POST
  },
  UPDATE(id: string | number): string {
    return `${BASE_WEB_OPERATOR()}/streamer/${id}`; //PUT
  },
  DELETE(id: string): string {
    return `${BASE_WEB_OPERATOR()}/streamer/${id}`; //DELETE
  },
  ACCEPT(id: string | number): string {
    return `${BASE_WEB_OPERATOR()}/streamer/${id}?moderationStage=ACCEPTED`;
  },
  DECLINE(id: string | number): string {
    return `${BASE_WEB_OPERATOR()}/streamer/${id}?moderationStage=REJECTED`;
  }
};

export async function getStreamersList(
  params: TStreamersListParams
): Promise<IStreamerListResponse> {
  return http
    .get(API_STREAMER.LIST(), {
      params,
      cacheTime: 0,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse<IStreamerListResponse>) => data);
}

export async function getStreamersListSearch(
  params: IStreamersListSearchParams
): Promise<IStreamerListResponse> {
  return http
    .get(API_STREAMER.LIST_SEARCH(), {
      params,
      cacheTime: 0,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse<IStreamerListResponse>) => data);
}

export async function createStreamer(payload: FormData): Promise<TStreamer> {
  return http.post(API_STREAMER.CREATE(), payload).then(({ data }) => data);
}

export async function updateStreamer(
  id: number | string,
  payload: FormData
): Promise<TStreamer> {
  return http.put(API_STREAMER.UPDATE(id), payload).then(({ data }) => data);
}

export async function acceptStreamer(id: number | string): Promise<void> {
  http.patch(API_STREAMER.ACCEPT(id));
}

export async function declineStreamer(id: number | string): Promise<void> {
  http.patch(API_STREAMER.DECLINE(id));
}

export async function deleteStreamer(streamerId: string): Promise<void> {
  return http.delete(API_STREAMER.DELETE(streamerId));
}
