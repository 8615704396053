




































































import { PropType } from 'vue';
import { pluralize } from '@/helpers/pluralize';
import BaseDialog from '@/components/BaseDialog.vue';
import CopyAddress from '@/components/CopyAddress.vue';
import { mapGetters } from 'vuex';
import { getStreamersListSearch } from '@/api/CopyStake/Streamers';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { DataTableHeader } from 'vuetify';
import { truncateString } from '@/helpers/formatString';
import { EStreamerSearchBy, IStreamersListSearchParams } from '@/api/schema';

type ISelectItem = {
  text: string;
  value: EStreamerSearchBy;
};

const DEFAULT_PAGINATION_OPTIONS = {
  page: 1,
  itemsPerPage: 5
} as const;

const DEFAULT_FILTER_OPTIONS = {
  searchBy: EStreamerSearchBy.OPERATOR_USER_ID,
  search: ''
} as const;

export default {
  name: 'CopyStakeSelectStreamersModal',

  components: {
    BaseDialog,
    CopyAddress
  },

  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data(): Record<string, unknown> {
    return {
      isStreamersLoading: false,
      streamers: [],
      totalStreamers: null,
      paginationOptions: { ...DEFAULT_PAGINATION_OPTIONS },
      tmpFilterOptions: { ...DEFAULT_FILTER_OPTIONS },
      filterOptions: { ...DEFAULT_FILTER_OPTIONS },
      selectedStreamers: []
    };
  },

  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    isOpened: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    searchByOptions(): ISelectItem[] {
      return [
        {
          text: 'Operator User ID',
          value: EStreamerSearchBy.OPERATOR_USER_ID
        },
        {
          text: 'Wallet Address',
          value: EStreamerSearchBy.PLAYER_WALLET_HASH
        },
        {
          text: 'Nickname',
          value: EStreamerSearchBy.NICKNAME
        }
      ];
    },
    currentSearchBy(): ISelectItem | null {
      return (
        this.searchByOptions.find(
          (item: ISelectItem) => item.value === this.filterOptions.searchBy
        ) ?? null
      );
    },
    searchPlaceholder(): string {
      if (!this.currentSearchBy) {
        return '';
      }

      return `Search by ${this.currentSearchBy.text}`;
    },
    headers(): DataTableHeader[] {
      return [
        { text: 'Operator User ID', value: 'operatorUserId' },
        { text: 'Wallet Address', value: 'playerWalletHash' },
        { text: 'Nickname', value: 'nickname' }
      ];
    },
    requestParams(): IStreamersListSearchParams {
      return {
        ...(this.filterOptions.search
          ? { [this.filterOptions.searchBy]: this.filterOptions.search }
          : {}),
        size: this.paginationOptions.itemsPerPage,
        page: this.paginationOptions.page - 1,
        operatorId: this.operatorId
      };
    },
    totalSelectedStreamers(): number {
      return this.selectedStreamers.length;
    },
    modalTitle(): string {
      return `Select ${this.pluralizeStreamer(this.multiple ? 2 : 1)}`;
    },
    isSelectButtonDisabled(): boolean {
      return !this.selectedStreamers.length;
    },
    selectButtonText(): string {
      if (!this.multiple) {
        return `Select streamer`;
      }

      return `Select ${this.totalSelectedStreamers} ${this.pluralizeStreamer(
        this.totalSelectedStreamers
      )}`;
    }
  },

  watch: {
    isOpened: {
      handler(): void {
        if (this.isOpened) {
          this.reset();
        }
      },
      immediate: true
    },
    requestParams: {
      async handler(): Promise<void> {
        await this.fetchStreamers();
      },
      deep: true
    }
  },

  methods: {
    truncateString,
    pluralizeStreamer(count: number): string {
      return pluralize(count, ['streamer', 'streamers', 'streamers']);
    },
    closeModal(): void {
      this.isOpened = false;
    },
    async reset(): Promise<void> {
      this.streamers = [];
      this.totalStreamers = null;
      this.paginationOptions.page = DEFAULT_PAGINATION_OPTIONS.page;
      this.paginationOptions.itemsPerPage =
        DEFAULT_PAGINATION_OPTIONS.itemsPerPage;
      this.filterOptions.searchBy = DEFAULT_FILTER_OPTIONS.searchBy;
      this.filterOptions.search = DEFAULT_FILTER_OPTIONS.search;
      this.selectedStreamers = [];
      await this.fetchStreamers();
    },
    async fetchStreamers(): Promise<void> {
      if (this.isStreamersLoading) {
        return;
      }

      try {
        this.isStreamersLoading = true;

        const { content: streamers, totalElements: totalStreamers } =
          await getStreamersListSearch(this.requestParams);

        this.streamers = streamers;
        this.totalStreamers = totalStreamers;
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isStreamersLoading = false;
      }
    },
    updateRequestOptions(newOptions: Record<string, unknown>): void {
      this.filterOptions.searchBy =
        newOptions.searchBy ?? DEFAULT_FILTER_OPTIONS.searchBy;
      this.filterOptions.search =
        newOptions.search ?? DEFAULT_FILTER_OPTIONS.search;

      this.paginationOptions.page = DEFAULT_PAGINATION_OPTIONS.page;
    },

    handleClickClearSearchInput(): void {
      this.updateRequestOptions({
        ...this.tmpFilterOptions,
        search: ''
      });
    },
    handleKeydownEnterSearchInput(): void {
      this.updateRequestOptions(this.tmpFilterOptions);
    },
    handleClickSearchButton(): void {
      this.updateRequestOptions(this.tmpFilterOptions);
    },
    handleClickCloseButton(): void {
      this.closeModal();
    },
    handleClickSelectButton(): void {
      if (this.isSelectButtonDisabled) {
        return;
      }

      this.$emit('select', this.selectedStreamers);
    }
  }
};
